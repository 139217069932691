import React, { useEffect, useRef, useState } from "react"
import { useGLTF, useAnimations, PositionalAudio } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import { Vector3 } from "three"


const MechFox = (props) => 
{
    const { char, pos } = props
    const group = useRef()
    const audio = useRef()
    const [ hasPlayed, setHasPlayed ] = useState(false)
    const { nodes, materials, animations } = useGLTF("./assets/models/characters/angel_of_death-transformed.glb")
    const { actions } = useAnimations(animations, group)



    useFrame(() =>
    {
        if(char.current)
        {
            const position = new Vector3(pos[-5], pos[1], pos[-8])
            const charPosition = char.current.translation()
            const distance = position.distanceTo(new Vector3(charPosition.x, charPosition.y, charPosition.z))
            
            if(distance < 8 && !hasPlayed)
            {
                audio.current.play()
                setHasPlayed(true)
            } 
            else if(distance > 8 && hasPlayed)
            {
                audio.current.stop()
                setHasPlayed(false)
            }
        }
    })
    return (
        <>
            <group scale={ 15 } ref={group} {...props} dispose={null}>
                <PositionalAudio
                    url="/assets/audio/ghost.mp3"
                    loop={ false }
                    distance={ 10 }
                    ref={ audio }
                />
      <mesh geometry={nodes.AM153_049_00_AM153_049_00_0.geometry} material={materials.AM153_049_00} position={[0.007, 0.337, -0.037]} rotation={[-Math.PI / 2, 0, -0.01]} scale={0.067} />

            </group>
        </>
    )
}



export default function NPCs(props)
{
    const { char } = props

    return(
        <>
            <MechFox 
                position={ [ 0, 2, 3 ] } 
                pos={ [ 6, 0.5, 6 ] } 
                rotation-y={ Math.PI * 3 } 
                char={ char } 
            />

            {/* <group>
                <PositionalAudio
                    url="/assets/audio/2.wav"
                    loop={ false }
                    distance={ 15 }
                    ref={ Robot1 }
                />
                <Robot 
                    position={ [ 41, 2.9, - 5 ] } 
                    pos={ [ 41, 1, - 5 ] }
                    rotation-y={ Math.PI * - 1.3 }
                    rotation-x={ Math.PI * - 0.01 } 
                    char={ char }
                    scale={ 4 }
                    played={ false }
                    audio={ Robot1 }
                />
            </group> */}

{/* 
            <group>
                <PositionalAudio
                    url="/assets/audio/3.wav"
                    loop={ false }
                    distance={ 10 }
                    ref={ Robot2 }
                />
                <Robot 
                    position={ [ 240, 13.6, 95 ] } 
                    pos={ [ 240, 14, 95 ] }
                    rotation-y={ - Math.PI * 1.3 } 
                    char={ char }
                    played={ false }
                    audio={ Robot2 }
                />
            </group> */}
        </>
    )
}

useGLTF.preload("./assets/models/characters/angel_of_death-transformed.glb")

useGLTF.preload("./assets/models/characters/mech-fox.glb")
useGLTF.preload("./assets/models/characters/robot.glb")