import { useKeyboardControls } from "@react-three/drei";

export default function MobileInterface() {
  return (
    <>
      <div className="mobileInterface">
        <div className="flex justify-end pr-2 text-md text-white">
          <div className="">{/* { boxes } */}</div>
        </div>
      </div>
    </>
  );
}
