import { useProgress } from "@react-three/drei";
import { useEffect, useState } from "react";
import telegram from "../assets/telegram.svg";
import twitter from "../assets/twitter.svg";
import pumpfun from "../assets/pumpfun.svg";
import sunpump from "../assets/sunpump.svg";
import dex from "../assets/dex.svg";

export const LoadingScreen = (props) => {
  const { started, setStarted } = props;
  const { progress, total, loaded, item } = useProgress();
  const [showStartButton, setShowStartButton] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (progress === 100) {
      setShowStartButton(true);
    }
  }, [progress]);

  const handleStartClick = () => {
    setStarted(true);
  };

  return (
    <>
      {showLoading && (
        <div
          className={`
              fixed top-0 left-0 w-full h-full z-50 transition-opacity 
              duration-1000
              flex items-center justify-center bg-[#000000] 
              ${started ? "opacity-0" : "opacity-100"}
          `}
        >
          {showStartButton && progress === 100 && (
            <div className="flex flex-col gap-2">
              <button
                className="text-3xl md:text-5xl font-bold text-[#ff0000] transition-all duration-500"
                onClick={() => {
                  handleStartClick();
                  setShowStartButton(false);
                  setShowLoading(false);
                }}
                style={{
                  border: "2px solid #ffffff",
                  borderRadius: "30px",
                  padding: "25px",
                }}
              >
                Click To Enter
              </button>
              <p className="text-xl md:text-2xl font-bold text-[#ff0000] transition-all duration-500 mt-4 text-center font-serif">
                {`CA : To Be Updated`}
              </p>
              <section className="flex justify-between items-center mt-4">
                <a
                  className="cursor-pointer"
                  href={"https://t.co/xcLgmVHRaF"}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ pointerEvents: "auto" }}
                >
                  <img
                    src={telegram}
                    style={{ height: "30px", width: "30px" }}
                  />
                </a>
                <a
                  className="cursor-pointer"
                  href={"https://x.com/TronSunverse"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={twitter}
                    style={{ height: "30px", width: "30px" }}
                  />
                </a>
                <a
                  className="cursor-pointer"
                  href={"https://dexscreener.com/"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={dex} style={{ height: "30px", width: "30px" }} />
                </a>
                <a
                  className="cursor-pointer"
                  href={""}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={sunpump}
                    style={{ height: "30px", width: "30px" }}
                  />
                </a>
              </section>
            </div>
          )}
          {progress !== 100 && (
            <div className="text-3xl md:text-5xl font-bold text-[#ff0000] relative">
              <div
                className="absolute left-0 top-0  overflow-hidden truncate text-clip transition-all duration-500"
                style={{
                  width: `${progress}%`,
                }}
              >
                {progress.toFixed(0)}%
              </div>
              <div className="opacity-40">{progress.toFixed(0)}%</div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
