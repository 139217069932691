import React from 'react'
import { useGLTF } from '@react-three/drei'
import { Float } from '@react-three/drei'


export function Satan(props) {
  const { nodes, materials } = useGLTF('./assets/models/satan-transformed.glb')
  return (
    <group {...props} dispose={null}>
            {/* <mesh geometry={nodes.Ba_Satan_0.geometry} material={materials.Satan} scale={2} position={[-5.258, 405.563, -1125.674]} /> */}

      <mesh geometry={nodes.Circle001_Throne_0.geometry} material={materials.Throne} position={[0.006, 9.412, -0.014]} />
      <mesh geometry={nodes.Cube006_Throne_0.geometry} material={materials.Throne} position={[0.006, -12.156, 4.235]} />

      <Float speed={5} floatIntensity={5}>
      <mesh geometry={nodes.Plane001_Book_0.geometry} material={materials.Book} scale={2} position={[0.006,87.636, 28.403]} />
      </Float>
    </group>
  )
}

useGLTF.preload('./assets/models/satan-transformed.glb')
