import * as THREE from "three"
import React, { useRef, useMemo, useEffect, useState } from 'react'
import { useFrame, useThree, extend } from "@react-three/fiber"
import { useTexture, useGLTF, Text3D, Center, shaderMaterial, Sparkles, SpriteAnimator } from "@react-three/drei"
import { CuboidCollider, RigidBody } from "@react-three/rapier"
import { Color, AdditiveBlending, FogExp2 } from 'three'
import { createNoise2D, createNoise3D } from 'simplex-noise'
import { Satan } from "./Satan"
import { Satan2 } from "./Satan2"

import { Model } from './Worm_cult.jsx';


import { DoubleSide } from 'three';



const FlameSprite = React.memo(({ position, scale }) => {
  return (
    <SpriteAnimator
      scale={scale}
      position={position}
      startFrame={0}
      fps={40}
      autoPlay
      loop
      textureImageURL="./textures/flame.png"
      textureDataURL="./textures/flame.json"
      alphaTest={0.01}
    />
  );
});

// Portal shader material
const PortalMaterial = shaderMaterial(
  { 
    uTime: 0, 
    uColorStart: new Color('hotpink'), 
    uColorEnd: new Color('white') 
  },
  // Vertex Shader
  `
  varying vec2 vUv;
  void main() {
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectionPosition = projectionMatrix * viewPosition;
    gl_Position = projectionPosition;
    vUv = uv;
  }
  `,
  // Fragment Shader
  `
  uniform float uTime;
  uniform vec3 uColorStart;
  uniform vec3 uColorEnd;
  varying vec2 vUv;

  // Simplex 3D Noise
  vec4 permute(vec4 x) { return mod(((x*34.0)+1.0)*x, 289.0); }
  vec4 taylorInvSqrt(vec4 r) { return 1.79284291400159 - 0.85373472095314 * r; }
  float snoise(vec3 v) {
    const vec2  C = vec2(1.0/6.0, 1.0/3.0);
    const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);
    vec3 i  = floor(v + dot(v, C.yyy));
    vec3 x0 = v - i + dot(i, C.xxx);
    vec3 g = step(x0.yzx, x0.xyz);
    vec3 l = 1.0 - g;
    vec3 i1 = min(g.xyz, l.zxy);
    vec3 i2 = max(g.xyz, l.zxy);
    vec3 x1 = x0 - i1 + C.xxx;
    vec3 x2 = x0 - i2 + C.yyy;
    vec3 x3 = x0 - D.yyy;
    i = mod(i, 289.0);
    vec4 p = permute(permute(permute(
              i.z + vec4(0.0, i1.z, i2.z, 1.0))
            + i.y + vec4(0.0, i1.y, i2.y, 1.0))
            + i.x + vec4(0.0, i1.x, i2.x, 1.0));
    float n_ = 0.142857142857;
    vec3  ns = n_ * D.wyz - D.xzx;
    vec4 j = p - 49.0 * floor(p * ns.z * ns.z);
    vec4 x_ = floor(j * ns.z);
    vec4 y_ = floor(j - 7.0 * x_);
    vec4 x = x_ *ns.x + ns.yyyy;
    vec4 y = y_ *ns.x + ns.yyyy;
    vec4 h = 1.0 - abs(x) - abs(y);
    vec4 b0 = vec4(x.xy, y.xy);
    vec4 b1 = vec4(x.zw, y.zw);
    vec4 s0 = floor(b0)*2.0 + 1.0;
    vec4 s1 = floor(b1)*2.0 + 1.0;
    vec4 sh = -step(h, vec4(0.0));
    vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy;
    vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww;
    vec3 p0 = vec3(a0.xy, h.x);
    vec3 p1 = vec3(a0.zw, h.y);
    vec3 p2 = vec3(a1.xy, h.z);
    vec3 p3 = vec3(a1.zw, h.w);
    vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
    p0 *= norm.x;
    p1 *= norm.y;
    p2 *= norm.z;
    p3 *= norm.w;
    vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
    m = m * m;
    return 42.0 * dot(m*m, vec4(dot(p0,x0), dot(p1,x1), dot(p2,x2), dot(p3,x3)));
  }

  void main() {
    vec2 displacedUv = vUv + snoise(vec3(vUv * 7.0, uTime * 0.1));
    float strength = snoise(vec3(displacedUv * 5.0, uTime * 0.2));
    float outerGlow = distance(vUv, vec2(0.5)) * 4.0 - 1.4;
    strength += outerGlow;
    strength += step(-0.2, strength) * 0.8;
    strength = clamp(strength, 0.0, 1.0);
    vec3 color = mix(uColorStart, uColorEnd, strength);
    gl_FragColor = vec4(color, 1.0);
    #include <tonemapping_fragment>
    #include <encodings_fragment>
  }
  `
)

extend({ PortalMaterial })

export function Ground(props) 
{
  const { scene } = useThree()
  const { nodes } = useGLTF('./assets/models/world/portal-2.glb')
  const bakedTexture = useTexture('./assets/models/world/baked-02.jpeg')

  const [
    colorMap,
    displacementMap,
    normalMap,
    roughnessMap,
    aoMap,
    emissiveMap,
    metallicMap,
    opacityMap
  ] = useTexture([
    './textures/Color_1K.png',
    './textures/Height_1K.png',
    './textures/NM_1K.png',
    './textures/Roughness_1K.png',
    './textures/AO_1K.png',
    './textures/Emissive_1K.png',
    './textures/Metallic_1K.png',
    './textures/Opacity_1K.png'
  ])

  // Set texture wrapping and repeat
  const textures = [colorMap, displacementMap, normalMap, roughnessMap, aoMap, emissiveMap, metallicMap, opacityMap]
  textures.forEach(texture => {
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(8, 8)
  })

  const terrainSize = 100
  const resolution = 100
  const wallHeight = 8

  // Fog state
  const [fogDensity, setFogDensity] = useState(0.02)

  // Add fog to the scene and update it
  useEffect(() => {
    scene.fog = new FogExp2(0x330000, fogDensity)
    
    const timer = setTimeout(() => {
      setFogDensity(0.04) // Increase fog density after 3 seconds
    }, 300)

    return () => {
      clearTimeout(timer)
      scene.fog = null // Clean up fog when component unmounts
    }
  }, [scene, fogDensity])

  // Update fog density
  useFrame(() => {
    if (scene.fog) {
      scene.fog.density = fogDensity
    }
  })


  const planeGeometry = useMemo(() => {
    const geometry = new THREE.PlaneGeometry(terrainSize, terrainSize, resolution, resolution)
    const { position } = geometry.attributes

    const noise2D = createNoise2D()
    const noise3D = createNoise3D()

    const bumpCenters = [
      { x: 0.3, y: 0.3, radius: 0.2 },
      { x: -0.4, y: -0.2, radius: 0.15 },
      { x: 0.1, y: -0.5, radius: 0.25 },
    ]

    for (let i = 0; i < position.count; i++) {
      const x = position.getX(i) / terrainSize
      const y = position.getY(i) / terrainSize
      
      let z = 0

      const edgeSize = 0.1
      if (Math.abs(x) > 0.5 - edgeSize || Math.abs(y) > 0.5 - edgeSize) {
        const distanceToEdge = Math.min(
          Math.abs(Math.abs(x) - (0.5 - edgeSize)),
          Math.abs(Math.abs(y) - (0.5 - edgeSize))
        )
        
        z = wallHeight * (1 - Math.pow(distanceToEdge / edgeSize, 2))
        
        const ridgeNoise = noise2D(x * 30, y * 30) * 2
        z += ridgeNoise * (wallHeight / 4)
        
        const hillNoise = noise2D(x * 5, y * 5) * 3
        z += hillNoise * (1 - distanceToEdge / edgeSize)
        
        z += noise3D(x * 50, y * 50, z) * (wallHeight / 10)
      } else {
        for (const center of bumpCenters) {
          const distance = Math.sqrt((x - center.x)**2 + (y - center.y)**2)
          if (distance < center.radius) {
            const bumpIntensity = Math.cos((distance / center.radius) * Math.PI) * 0.5 + 0.5
            const detailNoise = noise3D(x * 20, y * 20, bumpIntensity * 10) * 2
            z += detailNoise * bumpIntensity * 3
          }
        }
        
        z += noise2D(x * 10, y * 10) * 0.1
      }

      position.setZ(i, z)
    }

    geometry.computeVertexNormals()
    return geometry
  }, [])

  const materialRef = useRef()
  const bluePortalRef = useRef()
  const redPortalRef = useRef()

  const portalMaterial = useRef()

  useFrame((state, delta) => {
    bluePortalRef.current.uTime += delta
    redPortalRef.current.uTime += delta

  })
  return (
    <RigidBody gravityScale={1} {...props} type="fixed" colliders={false}>
      <mesh 
        receiveShadow 
        castShadow
        position={[0, -1, 0]} 
        rotation-x={-Math.PI / 2} 
        geometry={planeGeometry}
      >
        <meshStandardMaterial 
          ref={materialRef}
          map={colorMap}
          // displacementMap={displacementMap}
          normalMap={normalMap}
          roughnessMap={roughnessMap}
          aoMap={aoMap}
          emissiveMap={emissiveMap}
          // metalnessMap={metallicMap}
          alphaMap={opacityMap}
          displacementScale={1}
          emissive={new THREE.Color(0xff5500)}
          emissiveIntensity={1.5}
          metalness={0}
          roughness={1}
          transparent={true}
          side={THREE.DoubleSide}
        />
      </mesh>
      <Satan scale={0.05} position={[0,-1,0]}/>
      <Satan2/>
      {/* <group position={[0, 1, 0]} scale={3} >

      <Center position={[-0.1, 2.5, 0]}>
        <Text3D letterSpacing={0.01} size={0.1} font="./inter_Bold.json">
          Project [X]
          <meshStandardMaterial side={DoubleSide} color="red" />
        </Text3D>
      </Center>
      <Center position={[-0.1, 2, 0]}>
        <Text3D letterSpacing={0.01} size={0.4} font="./inter_Bold.json">
          Choose Wisely
          <meshStandardMaterial side={DoubleSide} color="red" />
        </Text3D>
      </Center>
    </group> */}
      <FlameSprite position={[0, 1.8, 0]} scale={[3.5, 3.5, 3.5]} />
    
      {/* Blue Portal (Good) */}
      <group position={[30, -1, 0]} scale={4} rotation={[0, Math.PI / 2, 0]}>
        <mesh geometry={nodes.portalCircle.geometry} position={[0, 0.78, 1.6]} rotation={[-Math.PI / 2, 0, 0]}>
          <portalMaterial ref={bluePortalRef}           side={THREE.DoubleSide} blending={AdditiveBlending} uColorStart="blue" uColorEnd="white"  />
        </mesh>
        <mesh geometry={nodes.baked.geometry} position={[0.9, 0.34, -1.47]} rotation={[0, 0.14, 0]}>
          <meshBasicMaterial map={colorMap}            displacementScale={0.5}
          side={THREE.DoubleSide}          />
        </mesh>
        <Sparkles count={20} scale={[4, 1.5, 4]} size={6} speed={0.3} />
      </group>

      {/* Red Portal (Evil) */}
      <group position={[-30, -1, 0]} scale={4}  rotation={[0, -Math.PI / 2, 0]}>
        <mesh geometry={nodes.portalCircle.geometry} position={[0, 0.78, 1.6]} rotation={[-Math.PI / 2, 0, 0]}>
          <portalMaterial ref={redPortalRef} blending={AdditiveBlending} uColorStart="red" uColorEnd="orange"  />
        </mesh>
        <mesh geometry={nodes.baked.geometry} position={[0.9, 0.34, -1.47]} rotation={[0, 0.14, 0]}>
        <meshBasicMaterial map={colorMap}            displacementScale={0.5}
          side={THREE.DoubleSide}          />        </mesh>
        <Sparkles count={20} scale={[4, 1.5, 4]} size={6} speed={0.3} />
      </group>

      <CuboidCollider args={[terrainSize/2, 1, terrainSize/2]} position={[0, -1, 0]} />

    </RigidBody>
  )
}

// Preload assets
useGLTF.preload('./assets/models/world/portal-2.glb')
useTexture.preload('./assets/models/world/baked-02.jpeg')
useTexture.preload([
  './textures/Color_1K.png',
  './textures/Height_1K.png',
  './textures/NM_1K.png',
  './textures/Roughness_1K.png',
  './textures/AO_1K.png',
  './textures/Emissive_1K.png',
  './textures/Metallic_1K.png',
  './textures/Opacity_1K.png'
])

export default Ground