import React, { useRef, useEffect, useMemo } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

export function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('./assets/models/characters/grim_reaper-transformed.glb')
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <primitive object={nodes._rootJoint} />
        <skinnedMesh name="Object_7" geometry={nodes.Object_7.geometry} material={materials.Normal_Mat} skeleton={nodes.Object_7.skeleton} />
        <skinnedMesh name="Object_8" geometry={nodes.Object_8.geometry} material={materials.Default} skeleton={nodes.Object_8.skeleton} />
        <skinnedMesh name="Object_9" geometry={nodes.Object_9.geometry} material={materials['08_-_Default']} skeleton={nodes.Object_9.skeleton} />
      </group>
    </group>
  )
}

useGLTF.preload('./assets/models/characters/grim_reaper-transformed.glb');