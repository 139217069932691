import React from 'react'
import { useGLTF } from '@react-three/drei'


export function Satan2(props) {
  const { nodes, materials } = useGLTF('./assets/models/satan-transformed.glb')
  return (
    <group {...props} dispose={null}>

      <mesh geometry={nodes.Circle001_Throne_0.geometry} material={materials.Throne} position={[0.006, 4.212, -0.014]} />

  
    </group>
  )
}

useGLTF.preload('./assets/models/satan-transformed.glb')
