import World from './World.jsx'
import Lights from '../Lights.jsx'
import Player from './Player.jsx'
import Effects from './Effects.jsx'
import { useRef } from 'react'
import { Ground } from './world/Ground.jsx'
import HorrorScene from './HorrorScene.jsx'

export default function Experience({ downgradedPerformance = false })
{

    const ref = useRef()

    return(
    <>
        <Ground rotation={[0, Math.PI, 0]} />
        <Player  ref={ ref } />
        <HorrorScene/>
        <World char={ ref } />
        <Lights 
            char={ ref } 
            downgradedPerformance={ downgradedPerformance } 
        />
            <Effects />

    </>
    )
}