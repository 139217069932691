import React, { useRef, useMemo, useEffect, useState } from 'react';
import {  useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

const Rain = ({ count = 5000, onRainDrop }) => {
  const rainGroup = useRef();
  const velocities = useRef(new Float32Array(count));
  const positions = useRef(new Float32Array(count * 3));

  const raindrops = useMemo(() => {
    const geometry = new THREE.BufferGeometry();
    const temp = new Float32Array(count * 6 * 3);

    for (let i = 0; i < count; i++) {
      const x = Math.random() * 60 - 30;
      const y = Math.random() * 40 - 10;
      const z = Math.random() * 60 - 30;

      positions.current[i * 3] = x;
      positions.current[i * 3 + 1] = y;
      positions.current[i * 3 + 2] = z;

      const length = 0.1 + Math.random() * 0.2;
      const width = 0.001 + Math.random() * 0.004;

      for (let j = 0; j < 6; j++) {
        temp[i * 18 + j * 3] = x + (j % 2 === 0 ? -width : width);
        temp[i * 18 + j * 3 + 1] = y + (j < 2 ? 0 : -length);
        temp[i * 18 + j * 3 + 2] = z;
      }

      velocities.current[i] = 0.05 + Math.random() * 0.05;
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(temp, 3));
    const material = new THREE.MeshBasicMaterial({
      color: 0x8899aa,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.4,
    });

    return new THREE.Mesh(geometry, material);
  }, [count]);

  useFrame(() => {
    const temp = raindrops.geometry.attributes.position.array;
    for (let i = 0; i < count; i++) {
      positions.current[i * 3 + 1] -= velocities.current[i];
      if (positions.current[i * 3 + 1] < -10) {
        positions.current[i * 3 + 1] = 30;
        onRainDrop(positions.current[i * 3], positions.current[i * 3 + 2]);
      }
      for (let j = 0; j < 6; j++) {
        temp[i * 18 + j * 3] = positions.current[i * 3] + (j % 2 === 0 ? -0.002 : 0.002);
        temp[i * 18 + j * 3 + 1] = positions.current[i * 3 + 1] + (j < 2 ? 0 : -0.15);
        temp[i * 18 + j * 3 + 2] = positions.current[i * 3 + 2];
      }
    }
    raindrops.geometry.attributes.position.needsUpdate = true;
  });

  return <primitive object={raindrops} ref={rainGroup} />;
};

const LightningBolt = ({ start, end, width, color }) => {
  const points = useMemo(() => {
    const pts = [];
    const segments = 20;
    for (let i = 0; i <= segments; i++) {
      const t = i / segments;
      const x = THREE.MathUtils.lerp(start.x, end.x, t) + (Math.random() - 0.5) * width * 2;
      const y = THREE.MathUtils.lerp(start.y, end.y, t);
      const z = THREE.MathUtils.lerp(start.z, end.z, t) + (Math.random() - 0.5) * width * 2;
      pts.push(new THREE.Vector3(x, y, z));
    }
    return pts;
  }, [start, end, width]);

  return (
    <line>
      <bufferGeometry>
        <bufferAttribute
          attachObject={['attributes', 'position']}
          count={points.length}
          array={new Float32Array(points.flatMap(p => [p.x, p.y, p.z]))}
          itemSize={3}
        />
      </bufferGeometry>
      <lineBasicMaterial color={color} linewidth={3} />
    </line>
  );
};

const Lightning = ({ onLightningStrike, audioContext }) => {
  const lightningRef = useRef();
  const lightIntensity = useRef(0);
  const { camera } = useThree();
  const [bolts, setBolts] = useState([]);
  const thunderSound = useRef(null);

  useEffect(() => {
    if (audioContext) {
      fetch('/thunder.mp3')
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
        .then(audioBuffer => {
          thunderSound.current = audioBuffer;
        });
    }
  }, [audioContext]);

  const playThunder = () => {
    if (audioContext && thunderSound.current) {
      const source = audioContext.createBufferSource();
      source.buffer = thunderSound.current;
      source.connect(audioContext.destination);
      source.start();
    }
  };

  const createLightningBolts = () => {
    const newBolts = [];
    const boltCount = Math.floor(Math.random() * 3) + 2;
    for (let i = 0; i < boltCount; i++) {
      const startX = (Math.random() - 0.5) * 60;
      const startZ = (Math.random() - 0.5) * 60;
      const start = new THREE.Vector3(startX, 30, startZ);
      const end = new THREE.Vector3(startX + (Math.random() - 0.5) * 20, -10, startZ + (Math.random() - 0.5) * 20);
      newBolts.push({ start, end, width: 0.2 + Math.random() * 0.3 });
    }
    setBolts(newBolts);
  };

  useFrame(() => {
    if (Math.random() > 0.992 || lightIntensity.current > 0) {
      if (lightIntensity.current === 0) {
        lightIntensity.current = 1;
        playThunder();
        createLightningBolts();
        onLightningStrike(true);
      } else {
        lightIntensity.current -= 0.05;
        if (lightIntensity.current < 0) {
          lightIntensity.current = 0;
          setBolts([]);
          onLightningStrike(false);
        }
      }
      lightningRef.current.intensity = lightIntensity.current * 5;
    }
  });

  return (
    <group>
      <pointLight
        ref={lightningRef}
        position={[camera.position.x, camera.position.y + 20, camera.position.z - 5]}
        intensity={0}
        distance={100}
        decay={1.5}
        color={0xffffcc}
      />
      {bolts.map((bolt, index) => (
        <LightningBolt key={index} {...bolt} color={0xffffcc} />
      ))}
    </group>
  );
};

const Ground = () => (
  <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -10, 0]} receiveShadow>
    <planeGeometry args={[100, 100]} />
    <meshStandardMaterial color={0x333333} />
  </mesh>
);

const RainDrop = ({ position }) => {
  const [scale, setScale] = useState(0.1);
  const dropRef = useRef();

  useEffect(() => {
    const timer = setTimeout(() => setScale(0), 300);
    return () => clearTimeout(timer);
  }, []);

  useFrame(() => {
    if (dropRef.current) {
      dropRef.current.scale.x = scale;
      dropRef.current.scale.y = scale;
      dropRef.current.scale.z = scale;
      setScale(prev => Math.max(0, prev - 0.01));
    }
  });

  return (
    <mesh ref={dropRef} position={[position[0], -9.9, position[1]]}>
      <circleGeometry args={[0.05, 32]} />
      <meshBasicMaterial color={0x8899aa} transparent opacity={0.6} />
    </mesh>
  );
};

const Cube = ({ position }) => {
  return (
    <mesh position={position} castShadow>
      <boxGeometry args={[2, 2, 2]} />
      <meshStandardMaterial color={0x8844aa} />
    </mesh>
  );
};

const Scene = ({ audioContext }) => {
  const [raindrops, setRaindrops] = useState([]);
  const [isLightning, setIsLightning] = useState(false);

  const handleRainDrop = (x, z) => {
    setRaindrops(prev => [...prev, [x, z]]);
    setTimeout(() => setRaindrops(prev => prev.slice(1)), 300);
  };

  return (
    <>
      <color attach="background" args={[0x001020]} />
      <fog attach="fog" args={[0x001020, 10, 50]} />
      <ambientLight intensity={0.05} />
      <Rain count={5000} onRainDrop={handleRainDrop} />
      <Lightning onLightningStrike={setIsLightning} audioContext={audioContext} />
      <Ground />
      {raindrops.map((pos, index) => (
        <RainDrop key={index} position={pos} />
      ))}
      <Cube position={[-5, -8, -5]} />
      <Cube position={[5, -8, 5]} />
      {isLightning && (
        <pointLight position={[0, 10, 0]} intensity={2} distance={100} decay={1} color={0xffffcc} />
      )}
    </>
  );
};

const HorrorScene = () => {
  const [audioContext, setAudioContext] = useState(null);
  const [sceneStarted, setSceneStarted] = useState(false);

  const startScene = () => {
    const context = new (window.AudioContext || window.webkitAudioContext)();
    setAudioContext(context);
    setSceneStarted(true);
  };



  return (
      <Scene audioContext={audioContext} />
  );
};

export default HorrorScene;