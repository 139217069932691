import { forwardRef } from 'react'
import { useFrame } from '@react-three/fiber'
import Ecctrl, { EcctrlAnimation } from 'ecctrl'

const Player = forwardRef((props, ref) =>
{
    useFrame(() =>
    {
        if(ref.current)
        {
            const charPosition = ref.current.translation()
            if(charPosition.y < - 30 )
            {
                ref.current.setTranslation( { x: -10, y: 10, z: 0 } )
                ref.current.setLinvel( { x: 0, y: 0, z: 0 } )
                ref.current.setAngvel( { x: 0, y: 0, z: 0 } )
            }
        }
    })
    

    return( 
        <>
            <Ecctrl
                ref={ ref }
                capsuleRadius={ 0.3 }
                capsuleHalfHeight={ 1.5 }
                friction={ 0.2 }
                camInitDis={ -2 }

                animated={ true }
                position={ [ 0, 10,-10 ] }
                maxVelLimit={ 3 }
                sprintMult={ 3 }
                jumpVel={ 3 }
                turnSpeed={ 10 }
                gravityScale={ 1 }
                autoBalanceDampingOnY={ 0.01 }

            >
     
            </Ecctrl>
        </>
    )
})

export default Player