import Platforms from "./world/Platforms"
import NPCs from "./world/NPCs"


export default function World(props)
{
    const { char } = props

    return(
    <>
        <group>
            <Platforms />
            <NPCs char={ char } />
        </group>
    </>
    )
}